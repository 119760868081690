var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item-dialog-bar"},_vm._l((_vm.actions),function(action,index){return _c('div',{key:("action-" + (action.id)),staticClass:"item-dialog-bar__action"},[_c('v-tooltip',{attrs:{"fixed":"","left":"","right":"","dark":_vm.dark,"transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(action.id === 'more')?_c('v-menu',{attrs:{"offset-y":"","min-width":"225","open-on-hover":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menuOn = ref.on;
var menuAttrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',Object.assign({}, attrs,
                menuAttrs),false),Object.assign({}, on,
                menuOn)),[_c('v-icon',[_vm._v(" mdi-dots-horizontal ")])],1)]}}],null,true),model:{value:(_vm.moreMenuModel),callback:function ($$v) {_vm.moreMenuModel=$$v},expression:"moreMenuModel"}},[_c('v-list',_vm._l((_vm.moreMenuItems),function(moreMenuItem){return _c('v-list-item',{key:("more-menu-item-" + (moreMenuItem.id)),on:{"click":function($event){return _vm.$emit('emitAction', moreMenuItem)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" "+_vm._s(moreMenuItem.icon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(moreMenuItem.label)+" ")])],1)],1)}),1)],1):_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","loading":_vm.actionInProcess === action.id,"color":action.id === 'favorite' && _vm.item.favorite && 'error' || 'inherit'},on:{"click":function () { return _vm.onClick(action); }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" "+_vm._s(action.id === 'favorite' ? ("" + (action.icon) + (!_vm.item.favorite && '-outline' || '')) : action.icon)+" ")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(action.label)+" ")]),(!action.withoutDivider)?[(index !== _vm.actions.length - 1)?_c('v-divider',{staticClass:"my-1",staticStyle:{"border-color":"transparent !important"}}):_vm._e()]:_vm._e()],2)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }