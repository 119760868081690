<template>
  <div
    class="item-dialog-bar"
  >
    <div
      v-for="(action, index) in actions"
      :key="`action-${action.id}`"
      class="item-dialog-bar__action"
    >
      <v-tooltip
        fixed
        left
        right
        :dark="dark"
        transition="slide-x-transition"
      >
        <template
          v-slot:activator="{on ,attrs}"
        >
          <v-menu
            offset-y
            min-width="225"
            v-model="moreMenuModel"
            :open-on-hover="false"
            v-if="action.id === 'more'"
          >
            <template v-slot:activator="{on: menuOn, attrs: menuAttrs }">
              <v-btn
                v-bind="{
                  ...attrs,
                  ...menuAttrs
                }"
                v-on="{
                  ...on,
                  ...menuOn
                }"
                icon
              >
                <v-icon>
                  mdi-dots-horizontal
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="moreMenuItem in moreMenuItems"
                :key="`more-menu-item-${moreMenuItem.id}`"
                @click="$emit('emitAction', moreMenuItem)"
              >
                <v-list-item-icon>
                  <v-icon>
                    {{ moreMenuItem.icon }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ moreMenuItem.label }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            v-else
            v-on="on"
            v-bind="attrs"
            icon
            :loading="actionInProcess === action.id"
            :color="action.id === 'favorite' && item.favorite && 'error' || 'inherit'"
            @click="() => onClick(action)"
          >
            <v-icon
              size="20"
            >
              {{ action.id === 'favorite' ? `${action.icon}${!item.favorite && '-outline' || ''}` : action.icon }}
            </v-icon>
          </v-btn>
        </template>
        {{ action.label }}
      </v-tooltip>
      <template
        v-if="!action.withoutDivider"
      >
        <v-divider
          style="border-color: transparent !important;"
          v-if="index !== actions.length - 1"
          class="my-1"
        />
      </template>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    dark: {
      type: Boolean
    },
    item: {
      required: true
    },
    type: {
      type: String,
      required: true,
      default: 'preview'
    }
  },
  name: 'ItemDialogBar',
  data: () => ({
    actionInProcess: null,
    moreMenuModel: false
  }),
  computed: {
    itemFavorite() {
      return this.item.favorite || null
    },
    moreMenuItems() {
      let result = [
        {
          id: 'sharing',
          label: 'Privacy and Sharing',
          icon: 'mdi-share-variant'
        },
        {
          id: 'code',
          label: 'Embed Code',
          icon: 'mdi-code-tags'
        },
        {
          id: 'archive',
          label: 'Archive',
          icon: 'mdi-archive'
        }
      ]
      return result
    },
    actions() {
      let result = [
        {
          id: 'edit',
          label: 'Edit Card',
          icon: 'mdi-pencil-outline'
        },
        {
          id: 'copy',
          label: 'Copy Card content',
          icon: 'mdi-content-copy',
          withoutDivider: true
        },
        {
          id: 'link',
          label: 'Copy Card URL',
          icon: 'mdi-link'
        },
        {
          id: 'favorite',
          label: 'Add to Favorites',
          icon: 'mdi-heart'
        },
        {
          id: 'more',
          label: 'More options',
          icon: 'mdi-dots-horizontal'
        }
      ]

      if (this.type === 'preview' && this.item.type === 'image') {
        result.splice(0, 1)
      }

      if (this.type === 'edit') {
        
        if (this.item.type === 'quote') {
          result = [
            {
              id: 'addQuoteBlock',
              label: 'Add block',
              icon: 'mdi-plus'
            }
          ]
        }

      }

      return result
    },
  },
  methods: {
    onClick(action) {
      this.actionInProcess = action.id
      this.$emit('emitAction', action)

      action.id !== 'favorite' && setTimeout(() => {
        this.actionInProcess = null
      }, 200)
    },
  },
  watch: {
    itemFavorite() {
      this.actionInProcess = null
    },
  }
}
</script>
<style lang="scss">
.item-dialog-bar {
  z-index: 2;
}
</style>